export default {
  lang_code: 'en',
  page_home: {
    we_build: 'Společně postavíme světově unikátní a rekordní obraz - cubevel',
    add_cube: 'přidej kostku',
  },
  page_own_quantinty: {
    you_build: 'Postavte světově unikátní a rekordní dílo',
    how_many: 'Kolik kostek si chcete zakoupit?',
    invalid_quantity: 'Chybný počet'
  },
  register: 'Registrovat',
  before_buy: ' před zakoupením?',
  benefits_of_registraion: 'výhody registrace',
  page_choose_cubes: {
    choose_cubes: 'Výběr kostek',
    cubes_description: ['Dostaneš jednu kostku v cubevelu Cesta a další jeden velký Bonus',
      'Dostaneš dvě kostky v cubevelu Cesta a dva velké Bonusy',
      'Můžeš si zvolit počet kostek v cubevelu Cesta a dostaneš výhodnější cenu a tři velké Bonusy',]
  },
  page_checkout: { //TODO Will be on the wordpress side
    heading: 'Pokladna',
    consent: 'Souhlasím s obchodními podmínkami a se zpracováním osobních údajů.',
    summary: 'SOUHRN',
    pcs: 'KS KOSTEK',
    currency: 'Měna',
    use_paywall: 'zaplatit přes platební bránu',
    use_paypal: 'zaplatit přes PayPal',
    pay: 'zaplatit',
    total: 'Celkem'
  },
  page_thank_you: {
    title: 'Děkujeme',
    subtitle: 'Nyní vypálíme kostky a přidáme je do cubevelu "Cesta" i "Přítel".<br>' +
      'Kostky budou vloženy podle pořadí přijatých objednávek.<br>' +
      'Důležité informace a další instrukce jsme zaslali na zadaný email.',
    additional: 'Kde se nachází zakoupené kostky zjistíš ve svém uživatelském účtu.'
  },
  page_login: {
    login: 'Přihlášení',
    register: 'Registrace',
    password: 'Heslo',
    repeat: 'Zopakujte heslo',
    password_doesnt_match: 'Hesla se neshodují!',
    benefit_1: 'Registrovaný uživatel se může podívat, kde jsou jeho kostky v obraze.',
    benefit_2: 'Registrovaný uživatel může nahlédnout do přehledu, kolik kostek vlastní jiní uživatelé.',
    benefit_3: 'hlasovat o dalším využití obrazu po skončení projektu (nebo o zapůjčení, prodeji, převodu apod.)',
    submit_login: 'přihlásit',
    submit_register: 'registrovat',
    remember: 'Přihlásit trvale',
    lost: 'Ztratili jste heslo?',
    alreadyLogged: 'Jste přihlášeni.'
  },
  page_lost: {
    reset: "Resetovat",
    title: "Resetovat heslo"
  },
  page_why: {
    heading: 'Co vlastně stavíme?',
    subheading: 'Staň se spolumajitelem jedinečného cubevelu s názvem Cesta.',
    astounding_size: 'OHROMUJÍCÍ VELIKOST',
    astounding_size_lines: ['Stavíme cubevel - unikátní obraz, poskládaný z dřevěných kostek.',
      'Cubevel bude mít velikost 10 x 10 metrů.',
      'Bude se skládat ze 144 000 kostek.'],
    unique_look: 'UNIKÁTNÍ VZHLED',
    unique_look_lines: ['Na obraze bude nápis „Já jsem cesta, pravda i život“ ve 33 jazycích !',
      'Kostky mají rozměry 27x27x27mm.',
      'Na každé kostce bude vypálena buď část písmena z textu  „Já jsem cesta, pravda i život“, nebo písmeno z textu v pozadí.', //TODO informace o pozadí textu
    ],
    true_message: 'PRAVDIVÉ POSELSTVÍ',
    true_message_lines: ['O pravdivosti poselství „Já jsem cesta, pravda i život“ se již přesvědčilo více než 2 miliardy lidí na celém světě',
      'Každý člověk má své vnitřní hluboké touhy, a hledá … Hledá např. smysl života. Pokud zvolí správnou cestu, najde ho.',
      'Ale jakou cestu? Jakou pravdu? Jaký život? Kdo je ta cesta, pravda i život?'],
    more: 'více...'
  },
  page_current_state: {
    title: 'Aktuální stav',
    what: 'Co to děláme?',
    caption_1: 'Celkový počet kostek v cubevelu Cesta je 144000.',
    caption_2: 'Aktuálně objednáno je {count} kostek.',
    caption_3: 'Fyzicky je přidáno {count} kostek. Kamera...',
    caption_4: 'Přehled o počtu kostek a počtu uživatelů...',
    caption_5: 'Mapa míst odkud přicházejí objednávky...',
    caption_6: 'Umístění kostek v cubevelu podle čísla...',
    only_for_registered: 'Pouze pro registrované',
    more: 'další informace'
  },
  pages_placement: {
    title: 'Umístění kostek v cubevelu',
    your_cubes: 'Vaše kostky',
    ordered: 'Obědnali jste',
    placed: 'Umístili jsme',
    all_ordered: 'Lidé koupili',
    total_stats: 'Celková statistika'
  },
  page_bonuses: {
    title: "Bonusy",
    bonuses: ["BONUS 1", "BONUS 2", "BONUS 3",],
    subheadings: ['Stejný počet kostek, který jsi objednal do cubevelu Cesta, přidáme do druhého cubevelu s názvem Přítel.',
      'Do kostek přidávaných do cubevelu Přítel budeme vkládat přání a prosby.',
      'Některé kostky můžeš darovat.',],
    descriptions: ['Vzhled cubevelu Přítel je tajný a kostky se přidávají zprava doleva a v dalším řádku naopak. Až se oba cubevely zcela zaplní kostkami, bude vzhled tohoto cubevelu odkryt otočením kostek.',
      'Než kostku přidáme do cubevelu, vyvrtáme do ní otvor. Poté vytiskneme přání nebo prosbu, kterou jste nám poslali, a stočené do ruličky jej vložíme do kostky.',
      'Darovaná osoba bude vlastnit kostku v cubevelu Cesta, a může poslat své přání nebo prosbu, které vložíme do kostky v cubevelu Přítel.'],
  },
  page_exact_cubes: {
    title: 'Výběr kostek',
    wanna_different: "Chcete jiný počet kostek?<br>Klikněte ZDE.",
  },
  page_who: {
    title: 'Kdo jsme a proč to děláme?'
  },
  cubes: ['1 kostka',
    '2 kostky',
    'libovolný počet',
    '3 a více kostek'],
  add: 'přidej',
  buy: 'koupit',
  min_max: 'min. 3 ks - max. 33 ks',
  discount: 'sleva',
  enter_pieces: 'zadejte počet kusů',
  why_add: "jaký obraz a co je to cubevel?",
  pages: {
    home: 'úvod',
    why: 'proč mám přidat kostku?',
    state: 'aktuální stav',
    checkout: 'pokladna',
    support_us: 'podpořte nás',

    conditions: 'obchodní podmínky',
    privacy: 'prohlášení o ochraně osobních údajů',
    who: 'kdo jsme a proč to děláme?',
    account: 'přihlášení / registrace'
  },
  page_account: {
    title: 'Osobní účet',
    orders: "Objednávky",
    per_page: 'Řádků na str.:',
    date: "Datum",
    count: "Počet",
    details: "Detaily účtu",
    first_name: 'Jméno',
    last_name: 'Příjmení',
    email: 'E-mail',
    company: 'Název firmy',
    address_fields: 'Tyto údaje jsou dobrovolné. Jejich prostřednictvím nám na vás můžete poskytnout kontaktní informace.',
    billing_address: "Fakturační adresa",
    address_2: 'Upřesnění k adrese',
    city: 'Město',
    state: 'Stát/Region',
    postcode: 'PSČ',
    phone: 'Telefonní číslo',
    country: 'Země',
    addressUndefined: "Adresa ještě nebyla zadána",
    delete: "Smazat účet",
    sure_delete: "Jste si jisti, že chcete trvale smazat svůj účet?",
    deleted_success: "Váš účet byl smazán.",
    not_logged_in: 'Nejste přihlášeni',
    log_first: 'Pro zobrazení této stránky se přihlašte.'
  },
  card_in_nav: {
    the_way: 'Cesta',
    build_with_us: 'Postav s námi světové unikátní a rekordní dílo.'
  },
  map: 'Mapa objednávek kostek',
  loading: 'Načítání...',
  loading_map: 'Načítání mapy...',
  error: 'Chyba',
  error_occured: 'Nastala chyba.',
  page_not_found: 'Stránka nenalezena.',
  logout: 'Odhlásit se',
  edit: 'Upravit',
  save: 'Uložit',
  cancel: 'Zahodit',
  errors: {
    dismissAll: 'Zavřít všechny',
    server: 'Další informace: ',
    decode: 'Nepodařilo se přečíst odpověď ze serveru',
    credentials: 'Nepodařilo se přihlásit s těmito údaji',
    auth_unknown: 'Neznámá chyba při přihlašování',
    duplicate_user: 'Uživatel s tímto emailem již existuje. <small>Můžete své údaje získat kliknutím na "Ztratili jste heslo?".</small>',
    logout: 'Nepodařilo se odhlásit. Problém můžete vyřešit <a href="https://support.google.com/accounts/answer/32050">vymazáním cookies v porhlížeči</a>.',
    edit: 'Neporařilo se uložit nové údaje',
    buy: 'Server neřijmul požadavek k platbě.',
    missing_fields: 'Zadejte všechny potřebné údaje.',
    consent: 'Prosíme, potvrďte souhlas s obchodními podmínkami.'
  },
  orders_table: {
    order_id: '# Objednávky',
    first_id: 'První 🧊',
    count: 'Počet 🧊',
    localTime: 'Kdy',
    placed: '# Umístěno',
    price: 'Cena',

  }
}
