//
//
//
//
//

export default {
  props: {
    link: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      logoPath: this.$store.state.appState.darkMode ? '/images/logoDark.svg' : '/images/logoLight.svg'
    }
  }
}
