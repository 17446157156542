import { get_timezone_id } from './timezoneDetect';
const timezone = get_timezone_id();

if (!('toJSON' in Error.prototype))
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function ()
    {
      var alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key)
      {
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true
  });

export default class BusinessLayer
{
  static apiServerRoot = process.env.NUXT_ENV_DATA_SERVER || '/api/';
  static developerMode = process.env.NODE_ENV == 'development' && !(process.env.NUXT_ENV_PRODUCTION ?? false);
  static localeCode = 'en_US';
  static onError = typeof (window) == 'undefined' ? (err) => { console.error(err); throw err; } : (obj) =>
  {
    window.alert(JSON.stringify(obj));
    throw obj;
  }
  static async decode(jsonResponsePromise)
  {
    var textResponse = await Promise.resolve(jsonResponsePromise);
    try
    {
      return await textResponse.json();
    }
    catch (e)
    {
      this.onError("errors.decode");
      console.warn(e)
    }
  }
  static getGlobalMap()
  {
    return this.decode(fetch(this.apiServerRoot + 'get.php', {
      method: 'POST',
      body: JSON.stringify({
        from: 'globalMap',
      })
    }).catch(this.onError))
  }
  /**
   * Poll the server for current state
   * @param {String} stateKind Can be: status, account, cubes, all, globalMap
   * @returns {Object} Decoded server state
   */
  static getServerState(stateKind)
  {
    return this.decode(fetch(this.apiServerRoot + 'get.php', {
      method: 'POST',
      credentials: "include",
      mode: this.developerMode ? 'no-cors' : undefined,
      body: JSON.stringify({
        from: stateKind,
      })
    }).catch(this.onError))
  }
  static async _auth(formData, url)
  {
    try
    {
      formData.append("ajax", "yes");
      var result = await fetch(this.apiServerRoot + url, {
        method: 'POST',
        redirect: "manual",
        credentials: "include",
        mode: this.developerMode ? 'no-cors' : undefined,
        body: formData
      });
      if (result.status == 202)//Success status (Accepted)
      {
        return await this.decode(result);
      }
      else if (result.status == 401)
      {
        this.onError("errors.credentials", await result.text());
        return false;
      }
      else if (result.status == 403)
      {
        this.onError("errors.duplicate_user", await result.text());
        return false;
      }
      else
      {
        this.onError("errors.auth_unknown", await result.text());
        return false;
      }
    }
    catch (e)
    {
      this.onError(e)
      return false;
    }
  }
  static register(formData)
  {
    var promise = this._auth(formData, 'register.php');
    return promise;
  }
  static login(formData)
  {
    var promise = this._auth(formData, 'login.php');
    return promise;
  }
  static async logout()
  {
    try
    {
      var formData = new FormData();
      formData.append("ajax", "yes");
      var result = await fetch(this.apiServerRoot + 'logout.php', {
        method: 'POST',
        redirect: "manual",
        credentials: "include",
        mode: this.developerMode ? 'no-cors' : undefined,
        body: formData
      });
      if (result.status == 202)//Success status (Accepted)
      {
        return await this.decode(result);
      }
      else
      {
        this.onError("errors.logout", await result.text());
        return false;
      }
    }
    catch (e)
    {
      this.onError(e);
      return { success: false };
    }
  }
  static async deleteAccount()
  {
    try
    {
      var result = await fetch(this.apiServerRoot + 'unregister.php', {
        method: 'POST',
        redirect: "manual",
        mode: this.developerMode ? 'no-cors' : undefined,
        credentials: "include"
      });
      if (result.status == 202)//Success status (Accepted)
      {
        return await this.decode(result);
      }
      else
      {
        this.onError("errors.logout", await result.text());
        return false;
      }
    }
    catch (e)
    {
      this.onError(e);
      return { success: false };
    }
  }
  static async editUser(jsonData)
  {
    try
    {
      var result = await fetch(this.apiServerRoot + 'edit.php', {
        method: 'POST',
        body: JSON.stringify(jsonData),
        redirect: "manual",
        mode: this.developerMode ? 'no-cors' : undefined,
        credentials: "include"
      });
      if (result.status == 202)//Success status (Accepted)
      {
        return await this.decode(result);
      }
      else
      {
        this.onError("errors.edit", await result.text());
        return false;
      }
    }
    catch (e)
    {
      this.onError(e);
      return { success: false };
    }
  }
  static async buy(jsonData)
  {
    try
    {
      var result = await fetch(this.apiServerRoot + 'buy.php', {
        method: 'POST',
        body: JSON.stringify(jsonData),
        redirect: "manual",
        mode: this.developerMode ? 'no-cors' : undefined,
        credentials: "include"
      });
      if (result.status == 202)//Success status (Accepted)
      {
        return await this.decode(result);
      }
      else
      {
        this.onError("errors.buy", await result.text());
        return false;
      }
    }
    catch (e)
    {
      this.onError(e);
      return { success: false };
    }
  }
}
