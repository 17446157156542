//
//
//
//
//
//
//
//

export default {
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  }
};
