//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  computed: mapState("projectState", [
    "myOrderedCount",
    "myPlacedCount",
    "myIndexes",
    "orderedCount",
    "placedCount",
    "userName",
    "userEmail",
  ]),
};
