//
//
//
//
//
//
//
//
//
//
//

import i18nHead from "../uiPlugins/i18nHead";
import UiPluginsMixin from "~/uiPlugins/";
export default {
  props: ["error"],
  layout: "error", // you can set a custom layout for the error page,
  mixins: [UiPluginsMixin],
  head() {
    return {
      ...i18nHead(this),
      title: this.$t("error"),
    };
  },
  computed: {
    errorText() {
      return this.error.statusCode === 404
        ? this.$t("page_not_found")
        : this.$t("error_occured");
    },
  },
};
