//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ErrorPanel from '~/components/ErrorPanel.vue';
import UiPluginsMixin from "~/uiPlugins/";

export default {
  components: { ErrorPanel },
  head()
  {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      meta: i18nHead.meta,
      link: i18nHead.link,
    };
  },
  mixins: [UiPluginsMixin],
};
