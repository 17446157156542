//
//
//

import i18nHead from "../uiPlugins/i18nHead";
import UiPluginsMixin from "~/uiPlugins/";
export default {
  head() {
    return i18nHead(this);
  },
  mixins: [UiPluginsMixin],
};
